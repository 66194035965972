:root {
  --primary-color: #1b1b1b;
  --light-color1: #e4e4e4;
  --dark-color: #151c2c;
  --gray: #242736;
  --white: #fff;
  --black: #000;
  --main-body-color: --white;
  --outer-color: #fbfbfb;
  --transparent: transparent;
  --light-color: #52526c47;
  --card-boxshadow: 0px 9px 20px rgba($font-color, 0.04);
  --main-title-color: #404040;
  --stroke-icon: #8e8f90;
  --font-color: #4bb99a;
  --light-text-color: #f3f3f3;
  --placeholder-color: #787878;
  --common-border: #e5e5e5;
  --disabled-input: #f2f2f2;
  --disabled-color: #a6a6a6;
  --datepicker-stroke-color: #808080;
  --text-color: #2e2e2e;
  --label-color: #4d4d66;
  --slate-color: #e6e6e6;
  --gray-100: #808080;
  --common-light-background:#f6f6f6;
  --body-color: #f7f5f2;
  // --bs-border-width: 0;
  
  // bootstrap colors
  --bs-body-color:#212529;
  --bs-body-bg: transparent;
  --bs-border-color:#dee2e6;
  
  // table
    --table-background: #f8f8f8;
    --bs-emphasis-color: #000;
    --pagination-disable: #d9d9d9;
    --pagination-bg: #ededed;
    --table-font-color: #4a5568;
  // Chart colors
    --chart-border-color: rgba(82, 82, 108, 0.2);
    --chart-text-color: #52526c;
}

$primary-color: #1b1b1b;
$secondary-color: #878f97;
$danger-color: #fc564a;
$warning-color: #ffb829;
$success-color: #3d8f5c;
$info-color: #5797c1;
$info-light-color: #3ca3fc;
$light-color1: #e4e4e4;
$dark-color: #151c2c;
$gray: #242736;
$white: #fff;
$black: #000;
$main-body-color: $white;
$outer-color: #fbfbfb;
$transparent: transparent;
$light-color: #52526c47;
$card-boxshadow: 0px 3px 4px rgba(46, 35, 94, 0.1);
$main-title-color: #404040;
$stroke-icon: #8e8f90;
$light-text-color: #f3f3f3;
$placeholder-color: #787878;
$common-border: #e5e5e5;
$disabled-input: #f2f2f2;
// $disabled-color: #8c8c8c;
$disabled-color: #a6a6a6;
$datepicker-stroke-color: #808080;
$text-color: #2e2e2e;
$label-color: #4d4d66;
$slate-color: #e6e6e6;
$gray-100: #808080;
$common-light-background:#f6f6f6;

$placeholder-disable: #cccccc;
$b-b-color: #dee2e6;
$modal-title-color: #3b4454;
$dark-body-background: #1f1f1f;

// table
$pagination-bg: #ededed;
$pagination-disable: #d9d9d9;
$font-color: #4bb99a;

// dark variables
$dark-font-label-color:#929292;
$dark-border-color: #434343;
$dark-disable-btn-color: #363636;
$dark-box-background: #262932;
$dark-font-color: #070707;
$modal-main-title-color: #d2d7df;
$dark-background-color: $dark-font-color;
$dark-small-font-color:#b3b3b3;
$light-all-font-color: #6a6a6a; 

.dark-only {
  --primary-color: #c7c7c7;
  --light-color1: #c0c0c0;
  --dark-color: #d5d5d5;
  --gray: #f0f1f5;
  --white: #141414;
  --black: #ffffff;
  --main-body-color: --white;
  --outer-color: #262626;
  --transparent: transparent;
  --light-color: #52526c47;
  --card-boxshadow: 0px 3px 4px rgba(46, 35, 94, 0.1);
  --main-title-color: #b3b8cc;
  --stroke-icon: #b3b2b2;
  --light-text-color: #262932;
  --placeholder-color: #6a6a6a;
  --common-border: #343434;
  --disabled-input: #f2f2f2;
  --disabled-color: #3a3838;
  --datepicker-stroke-color: #2d2d2d;
  --text-color: #2e2e2e;
  --label-color: #ffffff;
  --slate-color: #e6e6e6;
  --gray-100: #808080;
  --common-light-background:#141414;
  --body-color: #2a2a2a;
  --table-font-color: #c9c9c9;
  --table-background: #29302f;
    //   boostrap colors
    --bs-body-color:#b3b3b3;
    --bs-body-bg: transparent;
    --bs-border-color:#3d4751;

    // table
    --bs-emphasis-color: #979797;
    --pagination-disable:#1d1d1d;
    --pagination-bg: #0c0c0c;
    // Chart colors
    --chart-border-color: rgba(179,179,179,0.4);
    --chart-text-color: #c0c0c0;
}

// fonts
$font-serif: sans-serif;
$font-outfit: Outfit;
$font-awesome: "Font Awesome 6 Free";