.dashboard-section {
  padding: 10px 30px 30px 30px;
  margin-top: 112px;
}

.page-header{
  width: calc(100% - 300px);
  flex-wrap: wrap;
  gap: 25px;
  padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
  background-color: var(--white);
  box-shadow: 0px 3px 38px rgba(39, 32, 120, 0.1);
  position: fixed;
  margin-left: 300px;
  top: 0;
  left: 0;
  z-index: 4;
  transition: all .4s ease-in-out;
  .main-navbar {
    &.sticky{
      box-shadow: unset;
    }
    .signout {
      display: block;
      margin-left: auto;
    }
    .right-navbar {
      &.common-flex {
        @media screen and (max-width: 425px) { 
          gap: 5px;
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border: unset;
      padding: 8px 18px;
      background: linear-gradient(120.55deg, var(--font-color) 18.56%, rgb(27, 167, 116) 98.01%);
      transition: all 0.4s ease;
      &:hover,
      &:active,
      &.active {
        background: linear-gradient(120.55deg, rgb(27, 167, 116) 18.56%, var(--font-color) 98.01%);
        color: $white;
      }
      @media screen and (max-width: 425px) { 
        padding: 5px 10px;
      }
    }
  
  }
  &.close_icon {
    margin-left: 77px;
    width: calc(100% - 77px);
  }
}
.custom-pagination-transition {
  .pagination {
    .page-item {
      .back-pagination {
        @media screen and (max-width: 425px) { 
          padding: 3px 6px;
        
        }
        @media screen and (max-width: 375px) { 
          padding: 3px 6px;
        }
      }
      &.active {
        .page-link {
          @media screen and (max-width: 425px) { 
            padding: 3px 8px;
            font-size: 14px;

          }
          @media screen and (max-width: 375px) { 
            padding: 3px 6px;
          }
        }
      }
      .page-link {
        @media screen and (max-width: 425px) {
          padding: 3px 8px;
          font-size: 14px;
        }
        @media screen and (max-width: 375px) { 
          padding: 3px 6px;
        }
      }
    }
  }
}
// Datatable
table {
  &.data-transfer-table {
    margin: 0;

    thead,
    tbody {
      tr {
        th,
        td {
          padding: 12px;

          &:first-child {
            padding: 12px 12px 12px 0;
          }

          &:last-child {
            text-align: end;
            padding: 12px 0px 12px 12px;
          }
        }

        &:last-child {
          td {
            border: unset;
            padding-bottom: 0;
          }
        }
      }
    }

    thead {
      tr {
        th {
          font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
          padding-top: 0 !important;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          &.no-found {
            @include data-no-found(300px, 150px !important, 18px, var(--gray) !important);
          }
        }
      }
    }
  }
}

// Common form groups
.form-select,
.form-control {
  &:active,
  &:focus {
    border-color: var(--common-border) !important;
    box-shadow: unset;
  }

  &:disabled {
    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--common-border);
    }
  }
}

// Navbar
.right-navbar {
  li {
    position: relative;
    cursor: pointer;
    svg{
      width: 20px;
      height: 20px;
      fill: var(--gray);
      cursor: pointer;
    }
    .notify-dropdown {
      width: 350px;
      background-color: var(--white);
      box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
      position: absolute;
      top: 45px;
      right: 0px;
      z-index: -1;
      transition: 0.5s ease;
      opacity: 0;
      border-radius: 6px;
      visibility: hidden;
      transform: translateY(20px);
      overflow: hidden;
      left: unset;
      @media screen and (max-width: 575px) { 
        right: -100px;
        width: 285px;
      }
      @media screen and (max-width: 425px) { 
        right: -89px;
        width: 285px;
      }
      .notify-head{
        padding: 16px;
        background-color: var(--font-color);
        color: $white;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 6px 6px 0px 0px;
        i {
          animation: notify 3s ease 0.8s infinite;
        }
      }
      .notification-detail {
        padding: 14px 16px;
        width: inherit;
        height: 270px;
        overflow-y: auto;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          border: 1px solid var(--common-border);
          padding: 10px;
          margin-top: 12px;
          &:first-child {
            margin-top: 0;
          }
          .rounded-circle {
            min-width: 35px;
            min-height: 35px;
            background-color: rgba($font-color, 0.2);
            display: grid;
            place-items: center;
            border-radius: 50%;
            i {
              color: var(--font-color);
            }
          }
          // p {
          //   color: var(--font-color);
          // }
          h6 {
            margin: 0;
          }
          .title{
            font-weight: 500;
          }
          span{
            color: var(--gray-100);
            font-size: 14px;
          }
        }
      }
      .check-notify {
        display: flex;
        justify-content: center;
        padding: 15px;
        border-top: 1px solid var(--common-border);
        a {
          @include financial-flex(flex, center, center);
          padding: calc(7px + 5 * (100vw - 320px) / 1600) calc(14px + 18 * (100vw - 320px) / 1600) !important;
          font-weight: 500;
          transition: all 0.3s ease;
          position: relative;
          border: none;
          font-size: 16px;
          z-index: 0;
          gap: 8px;
          white-space: nowrap;
        }
      }
      &.no-notify {
        li {
          &:last-child {
            margin-top: 0;
          }
        }
      }
    }
    &:hover {
      .notify-dropdown {
        z-index: 4;
        opacity: 1;
        transition: 0.5s ease;
        visibility: visible;
        transform: translateY(0);
      }
    }
    .badge {
      position: absolute;
      right: -6px;
      top: -8px;
      border-radius: 50%;
      padding: 4px 8px;
      font-size: 10px;
    }
  }
  .light-box {
    i {
      position: relative;
      animation: notify 3s ease 0.8s infinite;
    }
  }
}
