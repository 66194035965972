// Budget CSS
// Add budget table class
table{
    &.budget-table{
        thead,tbody{
            tr{
                th,td{
                  .outer-box{
                    // width: 34px;
                    // height: 34px;
                    // background-color: var(--body-color);
                    // display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 6px;
                    box-shadow: 0px 0px 26px 6px rgba(var(--primary-color), 0.1);
                    .btn {
                      padding: 0;
                    }
                  }
                &:last-child{
                    .outer-box{
                        .trash-icon{
                            svg{
                                stroke: $danger-color;
                            }
                        }
                    }
                }
                  &:nth-child(7),&:nth-child(8){
                    i{
                        font-size: 26px;
                        &.treasury{
                            &-cancel{
                                color: rgba($secondary-color,0.8);
                            }
                            &-right{
                                color: $success-color;
                            }
                        }
                    }
                }
                svg{
                  stroke: var(--stroke-icon);
                }
                }
            }
        }
        &.bvi-table{
          thead,tbody{
            tr,td{
              &:nth-last-child(2){
                // text-align: center;
              }
            }
          }
          tbody{
            tr{
              td{
                &:nth-child(10){
                  .common-flex{
                    gap: 12px;
                    justify-content: left;
                    .outer-box{
                      svg {
                        vertical-align: text-top;
                      }
                      i{
                        color: $danger-color;
                        font-size: 18px;
                        vertical-align: middle;
                        cursor: pointer;
                      }
                    }
                  }
                }
                
              }
            }
          }
        }
    }
}
.budget-modal {
  .modal-body {
    .form-group {
      i {
        cursor: pointer;
      }
      [class*="text-"]{
        cursor: pointer;
      }
    }
    .form-control {
      background-color: $light-text-color;
      border: 1px solid $common-border;
      color: var(--dark-color);
    }
  }
}

.empty-notify{
  img{
    margin-bottom: 18px;
   animation: up-down-animate 6s ease-in-out infinite;
  }
}

// Notification table 
.notification-table{
  tbody{
    .notify-visible{
      p {
        font-size: 16px;
        color: var(--primary-color);
        font-weight: 500;
      }
      span{
        color: var(--primary-color);
      }
    }
    .notify-push{
      p {
        font-size: 16px;
        color: var(--table-font-color);
        font-weight: 500;
      }
      span{
        color: var(--gray-100);
      }
    }
  }
}

.common-dropdown{
  .dropdown-menu{
    background-color: $white;
    .dropdown-item{
      &:active,&:hover{
        background-color: var(--slate-color);
        color: var(--text-color);
      }
    }
  }
}


@keyframes up-down-animate{
  0%{
    transform: translateY(0);
  }
  50%{
    transform:translateY(-12px);
  }
  100%{
    transform: translateY(0);
  }

}
