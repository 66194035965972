// Heading ============
h1 {
    font-size: 28px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

