//bootstrap
@import "bootstrap/dist/css/bootstrap.min.css";

//utils
@import "./utils/variable";

// base
@import "./base/reset";
@import "./base/typography";
@import "./vendors/fontawesome.scss";

//components
@import "./components/auth";
@import "./components/dashboard";
@import "./components/account.scss";
@import "./components/budget";
@import "./components/bridge";

// themes
@import "./themes/dark";

//layout
@import "./layout/sidebar";

// react-date-range
@import "react-datepicker/dist/react-datepicker.css";
