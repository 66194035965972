// Dark CSS
body {
  &.dark-only {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $dark-font-color inset !important;
      -webkit-text-fill-color: $modal-main-title-color !important;
    }

    .main-navbar {
      &.sticky {
        box-shadow: unset;
      }
    }
    .login-section {
      .login-main {
        background-color: $dark-background-color;
      }
    }
    .common-table {
      .form-control ,.form-select {
        border-color: $dark-border-color !important;
        option {
          background-color: $dark-background-color;
        }
      }
      .list-product-body {
        .form-control {
          color: var(--light-color-1);
        }
      }
      &.custom-role-form  {
        ul {
          border-color: $dark-border-color;
        }
      }
    }
    .custom-role-input {
      color: var(--main-title-color) !important;
    }
    .right-navbar {
      li {
        .notify-dropdown {
          box-shadow: 0 0 20px $dark-box-background;
        }
      }
    }
    .custom-tabs-transaction {
      .nav-tabs {
        .nav-item {
          .nav-link.active {
            background-color: rgba($white, 0.6);
            color: $white;
          }
          a {
            color: $white;
          }
        }
      }
    }
    .common-table {
      &.custom-role-form {
        .custom-role-input {
          border-bottom: 1px solid $dark-border-color !important;  
        }
      }
    }
    .liquidity {
      background-color: $primary-color !important;
    }
    // Modal
    .modal {
      --bs-heading-color: var(--black);
      .modal-header {
        .btn-close {
          filter: invert(1);
        }
      }
      .modal-content {
        .modal-body {
          .form-label {
            color: $dark-font-label-color;
          }
        }
      }
      .account-deletion {
        .modal-content {
          .modal {
            &-body {
              .trash-box {
                h3 {
                  color: $modal-main-title-color;
                }
                p {
                  color: rgba($modal-main-title-color, 0.4);
                }
              }
            }
          }
        }
      }
    }

    // Budget modal
    .budget-modal {
      .modal-body {
        .form-control {
          background-color: $dark-background-color;
          border-color: $dark-border-color;
        }
      }
    }

    // Account
    .table {
      &.budget-table,
      &.account-table,
      &.transaction-table {
        thead,
        tbody {
          tr {
            th,
            td {
              .outer-box {
                box-shadow: 0px 0px 26px 6px rgba(var(--primary-color), 0.1);
              }
            }
          }
        }
      }
    }

    // Bridge tabs
    .bridge-tabs {
      .nav {
        &.nav-pills {
          .nav-item {
            .nav-link:not(.active) {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }

    // Transaction Modal
    .transaction-modal, .user-modal {
      .modal-content {
        .modal-body {
          .search-transaction, .search-users {
            border-color: $dark-border-color;
            background-color: $dark-background-color;
            &:disabled {
              &:hover,
              &:active {
                border-color: $dark-border-color !important;
              }
            }
            &:hover,
            &:focus {
              background-color: $dark-background-color !important;
            }
          }
        }
      }
    }

    .btn-light {
      --bs-btn-color: $white;
      border-color: $dark-disable-btn-color !important;
      background-color: $dark-disable-btn-color !important;
      &:hover {
        --bs-btn-hover-color: $white;
      }
    }
    // Datepicker
    .react-datepicker-popper {
      &[data-placement^="bottom"],
      &[data-placement^="top"],
      &[data-placement^="left"],
      &[data-placement^="right"] {
        .react-datepicker__triangle {
          fill: $dark-body-background;
          stroke: $dark-border-color;
          color: $dark-border-color;
        }
      }
    }
    .react-datepicker {
      background-color: $dark-body-background;
      color: $white;
      border-color: var(--bs-border-color);
      .react-datepicker__header {
        background-color: $dark-body-background;
        border-color: var(--bs-border-color);
        .react-datepicker__current-month {
          color: var(--gray);
        }
      }
      .react-datepicker__month-container {
        .react-datepicker__day-name {
          color: var(--bs-body-color);
        }
        .react-datepicker__month {
          .react-datepicker__day {
            color: rgba($white, 0.6);
            &:hover {
              background-color: $dark-box-background;
            }
            &.react-datepicker__day--disabled {
              color: rgba($white, 0.2);
            }
          }
        }
      }
    }
    // Calender icons
    .calander-icon {
      i {
        color: $light-all-font-color;
      }
    }

    // Charts
    .treasury-stats {
      .common-chart {
        .apexcharts-tooltip {
          &.apexcharts-theme-light {
            border-color: $dark-border-color;
            background-color: $dark-box-background;
            .apexcharts-tooltip-title {
              border-color: $dark-border-color;
              background-color: $dark-background-color;
            }
            .apexcharts-tooltip-series-group {
              .apexcharts-tooltip-text {
                color: rgba($white, 0.9);
              }
            }
          }
        }
        .apexcharts-xaxistooltip {
          background: $dark-background-color;
          border-color: $dark-border-color;
          &::before {
            border-bottom-color: $dark-border-color;
          }
          &::after {
            border-bottom-color: $dark-background-color;
          }
          .apexcharts-xaxistooltip-text {
            color: rgba($white, 0.9);
          }
        }
      }
    }

    // Copied to clipboard
    div[role="status"] {
      color: $dark-font-color;
    }
  }
}
