// Bridge CSS

table {
  &.transaction-table {
    thead,
    tbody {
      tr {
        th,
        td {
          .outer-box {
            // width: 34px;
            // height: 34px;
            // background-color: var(--body-color);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            box-shadow: 0px 0px 26px 6px rgba(var(--primary-color), 0.1);
            .btn {
              padding: 0;
            }
          }
          &:last-child{
            .outer-box{
              .trash-icon{
                svg{
                  stroke: $danger-color;
                }
              }
            }
          }
        }
      }
    }
  }
}
.bridge-tabs {
  margin-left: 12px;
  border-left: 1px solid var(--datepicker-stroke-color);
  padding-left: 12px;
  .nav {
    &.nav-pills {
      .nav-item {
        &:nth-child(2) {
          margin-inline: 10px;
        }
        .nav-link {
          color: $text-color;
          padding: 4px 12px;
          &.active {
            background-color: var(--font-color);
            color: $white;
          }
        }
      }
    }
  }
}
